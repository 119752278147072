
.form-check-input, .form-control, .no-focus:focus {
    outline: none !important;
    box-shadow: none !important;
    /* border: none; */
  }
.pagination{
    display: flex;
    justify-content: center;
    padding: 25px;
    gap: 1rem;
}
.pagination div button{
  background-color: #E6E6E6;
  color: black;
  border-radius: 50px;
  border: solid #E6E6E6;
  margin: 4px;
  text-align: center;
}
.pagination div button.active{
  background-color: #FFC857;
  border: solid #FFC857;
  color: white;
}
.cursorPointer{
  cursor: pointer;
}
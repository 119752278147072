.input-form {
  height: 46px;
  border-bottom: 2px solid gray;
  width: 300px;
  border: none;
  font-size: 14px;
  outline: none;
}

.specifique {
  border: none;
  padding: 2px;
  border: 1px solid #ced4da;
  font-size: 15px;
  height: 35px;
  border-radius: 8px;
  margin-top: 3px;
}

.specifique2 {
  border: none;
  padding: 2px;
  border: 1px solid #ced4da;
  font-size: 15px;
  height: 35px;
  border-radius: 8px;
  margin-top: 3px;
}

.searcher {
  border: none;
  border: 1px solid #ced4da;
  font-size: 16px;
  border-radius: 8px;
}

.searcher::placeholder {
  font-size: 15px;
}

.input-form::placeholder {
  font-size: 16px;
}

.input-form:focus {
  border: none;
  border-bottom: 1.333px solid #9f5540;
}

.specifique:focus {
  outline: none;
}

.specifique2:focus {
  outline: none;
}

.specifique::-webkit-outer-spin-button,
.specifique::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.specifique2::-webkit-outer-spin-button,
.specifique2::-webkit-inner-spin-button {
  -webkit-appearance: textfield;
  margin: 0;
}

.specifique::placeholder {
  font-size: 20px;
}

.specifique2::placeholder {
  font-size: 20px;
}

.header-form-add {
  background-color: #1f1e2f;
  color: white;
  padding: 13px 18px;
  width: fit-content;
  font-weight: 700;
  font-family: "Roboto";
  border-radius: 15px 0px;
}

.footer-form {
  font-family: "Inter";
  font-weight: 500;
  font-style: italic;
  color: #555;
}

.custom-progress-success .p-progressbar .p-progressbar-value {
  background-color: green !important;
}
.p-progressbar-value .p-progressbar-value-animate {
  background-color: green !important;
}
.p-progressbar-value {
  background-color: #00fc91 !important;
}
.nomHotel {
  font-family: "Plus Jakarta Sans";
  font-weight: 500;
  font-size: "17px";
  color: #18181b;
}

html,
body,
#root {
  /* height: 100%; */
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

a {
  outline: none !important;
  box-shadow: none !important;
  /* color: #009688 !important; */
  text-decoration: none !important;
  font-size: 12px;
}

.auth {
  .auth-background-holder {
    height: 100vh;
    min-height: 100%;
  }
}

.auth-main-col,
.auth-background-col {
  position: relative;
  margin-top: 40px;
  /* background-color: #009688; */
}

/* .auth-main-col {
  margin: auto;
} */

.auth-background-col {
  background: rgba();
  background-color: #007e72;
}

/* .auth-background-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
} */

.auth-wrapper {
  background: white;
  height: 100vh;
}

.auth-body {
  width: 336px;
  margin-top: 45px;
}

.auth-heading {
  font-size: 1.5rem;
}

.auth-option {
  font-size: 0.875rem;
  /* margin-bottom: 57px; */
}
/* a {
  color: #009688;
} */

/* .auth-option > a {
  color: $theme-text-color-secondary;
}

.forgot-password > a {
  color: $theme-text-color-secondary;
} */

input:focus {
  box-shadow: none;
}

@media (max-width: 767.98px) {
  .auth-background-col {
    display: none;
  }
}

/* .container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50;

} */
.paper {
  padding: 3;
  width: 400px;
}
.form {
  display: flex;
  flex-direction: column;
  grid-area: 3px;
}
.formulaire {
  display: flex;
  justify-content: center;
  margin-top: 72px;
  /* width: 400px; */
}
.img {
  height: 618px;
  width: 682px;
}
.paragraphe {
  color: #4b5563;
  text-align: left;
  font-size: 12px;
  font-family: Montserrat;
  /* font-style: normal; */
  /* font-weight: 300; */
}
.button-btn {
  color: #ffffff;
  padding: 6px;
  /* background-color: #009688; */
  font-size: 13.528px;
  font-style: normal;
  font-weight: 700;
  box-shadow: 0px 40px 80px 0px rgba(0, 150, 136, 0.15);
  border: none;
}
.button-white {
  border-radius: 31px;
  color: #080606;
  border: 2.647px solid #d0d5dd;
  padding: 6px;
  background-color: #fff;
  color: #344054;

  font-family: Montserrat;
  font-size: 13.528px;
  font-style: normal;
  font-weight: 700;
  /* height: 40px; */
  box-shadow: 0px 40px 80px 0px rgba(0, 150, 136, 0.15);
}
.custom-rounded {
  border-radius: 31px !important;
  background-color: #f3f4f6 !important;
  padding: 10px;
}

.title-connecter {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;

  text-align: left;
}
.form-control inputs {
  border-radius: 30px;
}
.form-label {
  color: gray;
  font-family: "Roboto";
  font-size: 15.87px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.form-check-label {
  color: #000000;
  font-family: Montserrat;
  font-size: 12.333px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.logo-app {
  /* margin-right: 50%; */
  text-align: left;
  margin-bottom: 25px;
}
.logo-app img {
  width: 100px;
}
.side {
  margin-bottom: 23px;
}
.linker {
  margin-top: 13px;
}
.allIconImage img {
  width: 70%; /* Ajuste la largeur de l'image pour qu'elle occupe 100% de la largeur de la div parent */
  height: auto; /* Ajuste automatiquement la hauteur pour conserver les proportions de l'image */
  display: block; /* Supprime l'espace réservé en bas de l'image */
  margin: 0 auto;
  margin-top: 70px;
}
.allIconImage {
  display: flex;
  justify-content: center;
  /* margin-bottom: 40px; */
}
.text-advice {
  margin: 0 auto;
  width: 70%;
  margin-top: 50px;
}
.text-advice h6 {
  color: rgba(255, 255, 255, 0.6);
  font-family: Montserrat;
  font-size: 18.667px;
  font-style: normal;
  font-weight: 600;
  line-height: 37.333px; /* 200% */
  letter-spacing: 2.8px;
}
.text-advice p {
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.navbar-container {
  display: flex;
  flex-wrap: wrap;
  background-color: "#FFFFFF" !important;
  position: fixed;
  z-index: 1000;
  height: 55px;
  border: none;
  margin: 0;
  width: 77.3%;
  top: 55px;
}

.navbar {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 38px;
}

.nav-item {
  text-decoration: none;
  font-weight: 420;
  color: black;
  position: relative;
  line-height: 34px;
  font-size: 18px;
  font-family: Roboto;
}

.nav-item.active:after {
  content: "";
  position: absolute;
  color: #e76f51;
  bottom: -10px;
  left: 1;
  right: 0;
  width: 100%;
  height: 4px;
  border-bottom: 2px solid #e76f51;
}
.nav-item.active {
  color: #e76f51;
}

.right-items {
  display: flex;
  align-items: center;
}

.search-bar {
  margin-right: 10px;
  padding: 5px;
}

.logout-button {
  padding: 8px 15px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #cc0000;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.166);
  z-index: 1000;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-children {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  width: 50%;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 25px;
  background-color: #60506d;
  color: white;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Roboto";
  border-radius: 5px;
}
.modal-close-icon {
  cursor: pointer;
}

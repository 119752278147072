.chart {
  color: #18181b;
  font-family: "Plus Jakarta Sans";
  font-size: "10px";
  font-weight: 700;
}
.rapport {
  font-family: "Roboto";
  font-size: 16px;
  color: #18181b;
}
.boutonCalendar .p-datepicker-trigger {
  background: none;
  border: none;
  box-shadow: none;
  color: black;
}
